import React, { useState } from 'react';
import { MdModeEditOutline, MdDeleteOutline } from "react-icons/md";
import AdminEditProduct from './AdminEditProduct';
import displayINRCurrency from '../helpers/displayCurrency';
import SummaryApi from '../common'

const AdminProductCard = ({ data, fetchdata }) => {
  const [editProduct, setEditProduct] = useState(false);
  const [deleteProduct, setDeleteProduct] = useState(false);
  const deleteProducts = async () => {
    try {
      const response = await fetch(`${SummaryApi.deleteProduct.url.replace(':productId', data._id)}`, {
        method: 'DELETE',
      });
  
      if (!response.ok) {
        throw new Error('Failed to delete the product');
      }
  
      const dataResponse = await response.json();
  
      console.log("Product deleted successfully", dataResponse);
  
      // Refresh the product list after deletion
      fetchdata();
      setDeleteProduct(false); // Close the delete confirmation modal
  
    } catch (error) {
      console.error('Error deleting the product:', error);
    }
  };
  

  const handleDelete = () => {
    deleteProducts();
  };
  

  return (
    <div className='bg-white p-4 rounded shadow-lg w-full max-w-xs md:max-w-sm lg:max-w-md mx-auto'>
      <div className='flex flex-col items-center'>
        <div className='w-full h-32 flex justify-center items-center'>
          <img
            src={data?.productImage[0]}
            className='mx-auto object-contain h-full max-w-full'
            alt={data?.productName}
          />
        </div>
        <h1 className='text-ellipsis line-clamp-2 font-medium text-sm md:text-base mt-2 text-center'>
          {data.productName}
        </h1>

        <div className='mt-2 text-center'>
          <p className='font-semibold text-lg md:text-xl'>
            {displayINRCurrency(data.sellingPrice)}
          </p>

          <div className='flex justify-center mt-2 space-x-2'>
            <div
              className='w-fit p-2 bg-green-100 hover:bg-green-600 rounded-full hover:text-white cursor-pointer'
              onClick={() => setEditProduct(true)}
            >
              <MdModeEditOutline />
            </div>
            <div
              className='w-fit p-2 bg-red-100 hover:bg-red-600 rounded-full hover:text-white cursor-pointer'
              onClick={() => setDeleteProduct(true)}
            >
              <MdDeleteOutline />
            </div>
          </div>
        </div>
      </div>

      {/** Render Edit Product Modal */}
      {editProduct && (
        <AdminEditProduct
          productData={data}
          onClose={() => setEditProduct(false)}
          fetchdata={fetchdata}
        />
      )}

      {/** Render Delete Confirmation Modal */}
      {deleteProduct && (
        <div className="fixed inset-0 flex items-center justify-center bg-black bg-opacity-50">
          <div className="bg-white p-4 rounded shadow-lg w-80">
            <h2 className="text-lg font-semibold">Confirm Deletion</h2>
            <p>Are you sure you want to delete this product?</p>
            <div className="flex justify-between mt-4">
              <button
                className="p-2 bg-red-500 text-white rounded hover:bg-red-600"
                onClick={handleDelete}
              >
                Delete
              </button>
              <button
                className="p-2 bg-gray-300 rounded hover:bg-gray-400"
                onClick={() => setDeleteProduct(false)}
              >
                Cancel
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
}

export default AdminProductCard;
